import { ToastTypes } from "./Enums";
import { Helpers } from "./Helpers";
import { KoData } from "./KoData";
import { Main } from "./Main";
import { Toastr } from "./Toastr";
export class FW {
    static init() {
        $("body")
            .on("click", "[data-staff-action]", FW.handleClick)
            .on("submit", "[data-staff-form-submit]", FW.handleFormSubmit)
            .on("change", "[data-staff-action-change]", FW.handleChange);
        let extendedDocument = Main.getDocument();
        extendedDocument.fw = {
            actions: {},
        };
        FW.log("Framework - initialised");
    }
    static registerActionHandlers(newActions) {
        Main.getDocument().fw.actions = Helpers.merge(Main.getDocument().fw.actions, newActions);
    }
    static handleClick(e) {
        return FW.handleAction(e, "data-staff-action");
    }
    static handleChange(e) {
        return FW.handleAction(e, "data-staff-action-change");
    }
    static handleFormSubmit(e) {
        return FW.handleAction(e, "data-staff-form-submit");
    }
    static handleAction(e, selector) {
        // Get the object that triggered the event
        let $ctrl = $(e.currentTarget);
        let action = $ctrl.attr(selector);
        if ($ctrl.hasClass("disabled"))
            return false;
        if ($.isFunction(Main.getDocument().fw.actions[action])) {
            let mappingKey = $ctrl
                .closest("[data-mapping-key]")
                .attr("data-mapping-key");
            let dataFor = ko.dataFor($ctrl.get(0));
            Main.getDocument().fw.actions[action](e, $ctrl, dataFor, KoData.data()[mappingKey]);
            return;
        }
        else {
            Toastr.toast(ToastTypes.ERROR, "Not Implemented", "This function has not yet been implemented<br/><i>(main::" +
                action +
                ")</i>");
        }
    }
    static log($msg, context) {
        console.log("[Staffweb frontend framework] " + $msg);
        if (context) {
            console.log(context);
        }
    }
    static throwError(msg) {
        throw new Error(msg);
    }
}
