import { FW } from "./Framework";
import { KoData } from "./KoData";
import { Translator } from "./Translator";
import { getCookie, setCookie } from "typescript-cookie";
import "../style/main.scss";
export class Main {
    static init() {
        let extendedDocument = Main.getDocument();
        if (!extendedDocument.init) {
            FW.init();
            KoData.init();
            Translator.init();
            extendedDocument.init = true;
            extendedDocument.token = sessionStorage.getItem("token");
            this.refreshAccessToken();
        }
    }
    static refreshAccessToken(originalRequest) {
        FW.log("Refreshing JWT Token");
        const refreshToken = getCookie("refresh_token");
        let refreshError = "";
        if (refreshToken && refreshToken !== "") {
            $.ajax({
                url: "/ep/relation-portal/token/refresh",
                type: "POST",
                data: JSON.stringify({ refresh_token: refreshToken }),
                contentType: "application/json",
                success: function (result) {
                    FW.log("JWT Token refreshed");
                    setCookie("api_token", result.token);
                    if (originalRequest) {
                        FW.log("Retrying original request");
                        $.ajax(originalRequest);
                    }
                },
                error: function (result) {
                    refreshError = JSON.stringify(result);
                },
                complete: function () {
                    if ("" !== refreshError) {
                        FW.log("JWT Token error" + refreshError);
                        setTimeout(function () {
                            window.location.replace("/logout");
                        }, 0);
                    }
                },
            });
        }
    }
    static ajax(settings) {
        if (settings) {
            const originalErrorHandler = settings.error;
            const originalGlobal = settings.global;
            settings.global = false; // Ignore global error handler defined in general.js.php. It would log us out without attempting to refresh the token.
            settings.error = function (jqXHR, textStatus, errorThrown) {
                if (jqXHR.status === 401) {
                    settings.global = originalGlobal;
                    settings.error = originalErrorHandler;
                    Main.refreshAccessToken(settings);
                }
                else if (originalErrorHandler !== undefined) {
                    if (Array.isArray(originalErrorHandler)) {
                        originalErrorHandler.forEach((handler) => handler(jqXHR, textStatus, errorThrown));
                    }
                    else {
                        originalErrorHandler(jqXHR, textStatus, errorThrown);
                    }
                }
            };
        }
        return $.ajax(settings);
    }
    static getDocument() {
        return document;
    }
}
// @ts-ignore
globalThis.$ = globalThis.jQuery = $;
$(() => {
    Main.init();
});
