import { Cookie } from "./Cookie";
import { Language } from "./Enums";
import { FW } from "./Framework";
import { Main } from "./Main";
import messagesEN from "./translations/messages.en.json";
import messagesFR from "./translations/messages.fr.json";
import messagesNL from "./translations/messages.nl.json";
export class Translator {
    static init() {
        let extendedDocument = Main.getDocument();
        extendedDocument.translations = {
            messages: new Map(),
            currentLang: Translator.determineCurrentLanguage(),
        };
        extendedDocument.translations.messages.set(Language.nl, messagesNL);
        extendedDocument.translations.messages.set(Language.fr, messagesFR);
        extendedDocument.translations.messages.set(Language.en, messagesEN);
        FW.log(`Translator - Initialised - current language: ${extendedDocument.translations.currentLang}`);
    }
    static translate(key, params) {
        let extendedDocument = Main.getDocument();
        let translations = extendedDocument.translations.messages.get(extendedDocument.translations.currentLang);
        if (!translations || !(key in translations)) {
            FW.log("Translator - key " + key + " not found");
            return key;
        }
        let translation = translations[key];
        if (params) {
            Object.keys(params).forEach((key) => {
                if (params.hasOwnProperty(key)) {
                    let value = params[key];
                    if (!key.startsWith("%")) {
                        key = "%" + key;
                    }
                    if (!key.endsWith("%")) {
                        key = key + "%";
                    }
                    translation = translation.replace(key, value);
                }
            });
        }
        return translation;
    }
    static determineCurrentLanguage() {
        let lang = Cookie.readCookie("ci_lang");
        if (!lang) {
            lang = sessionStorage.getItem("language");
        }
        if (lang === "be" || lang === null) {
            lang = "nl";
        }
        switch (lang) {
            case Language.nl:
                return Language.nl;
            case Language.fr:
                return Language.fr;
            case Language.en:
                return Language.en;
        }
    }
}
