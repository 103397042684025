import { FW } from "./Framework";
import { Helpers } from "./Helpers";
import { Main } from "./Main";
export class KoData {
    static init() {
        Main.getDocument().koData = {
            beforeBindActions: {},
            afterBindActions: {},
            data: {},
        };
        FW.log("KoData - initialised");
    }
    static data() {
        let extendedDocument = Main.getDocument();
        return extendedDocument.koData.data;
    }
    static loadPageData(uri, $object) {
        let dataComment = "";
        let currentNode;
        let iterator = document.createNodeIterator(document, NodeFilter.SHOW_COMMENT, () => {
            return NodeFilter.FILTER_ACCEPT;
        });
        while ((currentNode = iterator.nextNode())) {
            if (currentNode.nodeValue &&
                currentNode.nodeValue.indexOf("start pagedata #") !== -1) {
                dataComment = currentNode.nodeValue;
            }
        }
        if (dataComment.length > 0) {
            let actualRawData = Helpers.getTextBetweenDelimiters(dataComment, "start pagedata #", "# end pagedata");
            let data = JSON.parse(actualRawData);
            this.loadData(data, uri, $object);
        }
    }
    static bindData(key, data) {
        const extendedDocument = Main.getDocument();
        if (!extendedDocument.koData.data[key]) {
            extendedDocument.koData.data[key] = {};
        }
        this.update(data, key);
        return extendedDocument.koData.data[key];
    }
    static applyBindings(key, $object) {
        ko.applyBindings(Main.getDocument().koData.data[key], $object[0]);
    }
    static exportDataToJs(key) {
        return ko.mapping.toJS(Main.getDocument().koData.data[key]);
    }
    static addBeforeBindings(actions, mappingKey) {
        if (mappingKey === undefined) {
            FW.throwError("Mapping key undefined");
        }
        Main.getDocument().koData.beforeBindActions[mappingKey] = actions;
    }
    static addAfterBindings(actions, mappingKey) {
        if (mappingKey === undefined) {
            FW.throwError("Mapping key undefined");
        }
        Main.getDocument().koData.afterBindActions[mappingKey] = actions;
    }
    static loadData(data, key, $object) {
        FW.log("KoData - loading data");
        let observedData = KoData.bindData(key, data);
        FW.log("KoData - Applying before bind actions");
        KoData.koBeforeBind(key, observedData);
        FW.log("KoData - Applying bindings");
        KoData.applyBindings(key, $object);
        FW.log("KoData - Applying after bind actions");
        KoData.koAfterBind(key, observedData);
        // Let the rest of the application know that the data binding has been done
        let event = new Event("staffweb.ko.postBind", { bubbles: true });
        document.dispatchEvent(event);
    }
    static update(data, mappingKey) {
        if (Array.isArray(data)) {
            $(data).each((i, e) => {
                KoData.updateKoDataVariable(e, mappingKey);
            });
        }
        else {
            KoData.updateKoDataVariable(data, mappingKey);
        }
    }
    static updateKoDataVariable(data, mappingKey) {
        const extendedDocument = Main.getDocument();
        for (let x in data) {
            let y = ko.mapping.fromJS(data[x]);
            if ($.isFunction(y))
                y = y();
            if (extendedDocument.koData.data[mappingKey][x] == undefined) {
                if ($.isArray(y)) {
                    y = ko.observableArray(y);
                }
                if (!ko.isObservable(y))
                    extendedDocument.koData.data[mappingKey][x] = ko.observable(y);
                else
                    extendedDocument.koData.data[mappingKey][x] = y;
            }
            else {
                if (ko.isObservable(extendedDocument.koData.data[mappingKey][x]))
                    extendedDocument.koData.data[mappingKey][x](y);
                else
                    extendedDocument.koData.data[mappingKey][x] = y;
            }
        }
    }
    static koBeforeBind(key, data) {
        if (Main.getDocument().koData.beforeBindActions[key] === undefined) {
            return;
        }
        FW.log("KoData - Before bind for " + key);
        let methods = Object.getOwnPropertyNames(Main.getDocument().koData.beforeBindActions[key]);
        $(methods).each((i, e) => {
            // @ts-ignore:
            if ($.isFunction(Main.getDocument().koData.beforeBindActions[key][e])) {
                // @ts-ignore
                Main.getDocument().koData.beforeBindActions[key][e](data);
            }
        });
    }
    static koAfterBind(key, data) {
        if (Main.getDocument().koData.afterBindActions[key] === undefined) {
            return;
        }
        FW.log("KoData - After bind for " + key);
        let methods = Object.getOwnPropertyNames(Main.getDocument().koData.afterBindActions[key]);
        $(methods).each((i, e) => {
            // @ts-ignore:
            if ($.isFunction(Main.getDocument().koData.afterBindActions[key][e])) {
                // @ts-ignore
                Main.getDocument().koData.afterBindActions[key][e](data);
            }
        });
    }
}
